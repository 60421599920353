import {currentUser} from "@/firebase/firebaseAuth";
import {
    addDoc,
    collection,
    doc,
    getDoc,
    getDocs,
    query,
    QueryDocumentSnapshot,
    setDoc,
    where
} from "firebase/firestore";
import {firestore} from "@/firebase/firebaseConfig";
import {BrandingSettings, BrandingTemplate} from "@/lib/types";

export const brandingSettingsDefault: BrandingSettings = {
    brandColor: '#0BC484',
    accentColor: '#FFFFFF',
    menuBackgroundColor: '#333333',
    menuTextColor: '#DFDFDF',
    defaultQuality: '720',
    logoUrl: '',
    displayLogo: true,
    displayVideoTitle: true,
    centrePlayButton: true,
    centrePlayButtonShape: 'circle',
    playbackControls: true,
    roundedCorners: true,
    roundedCornersSize: 'medium',
    autoPlay: false,
    speedControl: true,
    autoMute: false,
    autoCaptions: false,
    loopVideo: false,
    minimalEmbedView: true,
    captionTextColor: '#FFFFFF',
    captionHighlightColor: '#ffCC00',
    publicPageBrandingActive: false,
    publicPageBackgroundColor: '#EEF4FA',
    publicPageButtonColor: '#0691F6',
    publicPageButtonTextColor: '#F8FAFC',
    publicPageLogoUrl: '',
    displayShareButton: true,
    displayCtaButton: false,
    ctaButtonUrl: '',
    ctaButtonLabel: '',
    displayCustomContent: false,
    displayFlags: true,
    languageMenuLocation: 'top',
    languageAutoSwitch: true,
}

export const brandingTemplateDefault: BrandingTemplate = {
    ...brandingSettingsDefault,
    id: undefined,
    displayName: 'Default Template',
    ownerUid: '',
}

export function saveBrandingTemplate(template: BrandingTemplate) {

    console.log(template)

    //Use firestore
    //If the id is empty, create a new document
    //If the id is not empty, update the existing document
    //Set the doc name as "Templates" + branding.id

    const requestData: BrandingTemplate = {...template}
    if (!requestData?.ownerUid) {
        requestData.ownerUid = currentUser()?.uid || ''
    }

    //Don't include id in the payload
    delete requestData.id

    console.log('Template', template)
    console.log('RequestData', requestData)

    if (!template.id) {
        const collectionRef = collection(firestore, "BrandingTemplates");
        console.log('Doc ref', collectionRef)
        return addDoc(collectionRef, requestData).then((ref) => {
            console.log('Document written with ID: ', ref)
            requestData.id = ref.id
            return requestData
        });
    } else {
        const docRef = doc(firestore, "BrandingTemplates", template.id);
        console.log('Doc ref', docRef)
        return setDoc(docRef, requestData).then((ref) => {
            console.log('Document written with ID: ', ref)
            requestData.id = template.id
            return requestData
        });
    }
}

export function getBrandingTemplate(id: string): Promise<BrandingTemplate | null> {
    console.log('Get branding template', id)

    if (!id) {
        return Promise.reject('BrandingTemplateId not provided')
    }

    return getDoc(doc(firestore, 'BrandingTemplates', id))
        .then((result) => {
            console.log('Result', result)
            if (!result.exists()) {
                return null
            }
            return mapBrandingTemplate(result)
        })
}

export function getBrandingTemplateByUid(uid: string): Promise<BrandingTemplate | null> {
    if (!uid) {
        return Promise.reject('Uid not provided')
    }

    const q = query(collection(firestore, "BrandingTemplates"),
        where("ownerUid", "==", uid));

    return getDocs(q).then((querySnapshot) => {
        if (querySnapshot.empty) {
            return null
        }
        return mapBrandingTemplate(querySnapshot.docs[0])
    })
}

export function getDefaultBrandingTemplate(uid: string): Promise<BrandingTemplate> {
    return listBrandingTemplates(uid).then((result) => {
        if (result.length > 0) {
            return result[0]
        }
        return brandingTemplateDefault
    })
}

export function listBrandingTemplates(uid: string): Promise<BrandingTemplate[]> {
    console.log('List branding templates', uid)
    if (!uid) {
        return Promise.reject('Uid not provided')
    }

    // return get collection(firestore, 'BrandingTemplates')
    const q = query(collection(firestore, "BrandingTemplates"),
        where("ownerUid", "==", uid));

    return getDocs(q).then((querySnapshot) => {
        const list: BrandingTemplate[] = []
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data());
            const data = mapBrandingTemplate(doc)
            if (data) {
                list.push(data)
            }
        })
        return list
    })
}

export function mapBrandingTemplate(doc: QueryDocumentSnapshot): BrandingTemplate | null {
    console.log('Map branding template', doc)
    return {...doc.data(), id: doc.id} as BrandingTemplate
}


export function mapToBrandingSettings(template?: BrandingTemplate | null): BrandingSettings | undefined {
    if (!template) {
        return undefined
    }
    return {
        ...template,
        brandColor: template.brandColor,
        accentColor: template.accentColor,
        menuBackgroundColor: template.menuBackgroundColor,
        menuTextColor: template.menuTextColor,
        logoUrl: template.logoUrl,
        displayLogo: template.displayLogo,
        displayVideoTitle: template.displayVideoTitle,
        centrePlayButton: template.centrePlayButton,
        centrePlayButtonShape: template.centrePlayButtonShape,
        playbackControls: template.playbackControls,
        roundedCorners: template.roundedCorners,
        roundedCornersSize: template.roundedCornersSize,
        defaultQuality: template.defaultQuality,
        autoPlay: template.autoPlay,
        autoCaptions: template.autoCaptions,
        speedControl: template.speedControl,
        autoMute: template.autoMute,
        loopVideo: template.loopVideo,
        minimalEmbedView: template.minimalEmbedView,
        captionTextColor: template.captionTextColor,
        captionHighlightColor: template.captionHighlightColor,
        publicPageBrandingActive: template.publicPageBrandingActive,
        publicPageBackgroundColor: template.publicPageBackgroundColor,
        publicPageButtonColor: template.publicPageButtonColor,
        publicPageButtonTextColor: template.publicPageButtonTextColor,
        publicPageLogoUrl: template.publicPageLogoUrl,
        displayShareButton: template.displayShareButton,
        displayCtaButton: template.displayCtaButton,
        ctaButtonUrl: template.ctaButtonUrl,
        ctaButtonLabel: template.ctaButtonLabel,
        displayCustomContent: template.displayCustomContent,
        displayFlags: template.displayFlags,
        languageMenuLocation: template.languageMenuLocation,
    } as BrandingSettings
}
